@import "./styles/*"
html, body
  height: 100%
  background-color: #33454B
.header
  height: 100%
.logo
  position: absolute
  width: 120px
  margin: 20px 0 0 30px
.hero
  font-family: "nimbus-sans",sans-serif
  height: 600px
  height: 100%
  position: relative
  
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  
  &::after
    content: ""
    // background-image: url('/images/hero.jpg')
    filter: blur(5px) grayscale(50%)
    background-size: cover
    // opacity: 0.5
    top: 0
    left: 0
    bottom: 0
    right: 0
    position: absolute
    z-index: -1
  
  .contact-cta
    margin-top: 2.4em
.tagline
  color: #fff
  font-size: 45px
  max-width: calc(100% - 60px)
  line-height: 1.3
  // text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5)
.contact-cta
  color: white
  // background-color: #007CFF
  background-color: #2298C9
  padding: 1em 1.5em
  cursor: pointer
  transition: all 0.2s
  &:hover
    background-color: lighten(#2298C9, 10%)
    transform: scale3d(1.02, 1.01, 1)
.about-cta
  position: absolute
  width: 100px
  left: 0
  right: 0
  margin-left: auto
  margin-right: auto
  text-align: center
  bottom: 26px
  color: #fff
.network-animation-container
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  overflow: hidden
  #network-animation
    width: calc(100% + 200px)
    height: calc(100% + 200px)
    margin-left: -100px
    margin-top: -100px